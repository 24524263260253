import axios from "axios"
import { BasesResponse } from "../lib/types/base"
import { Boat } from "../lib/types/boat"
import { OffersResponse } from "../lib/types/offer"

// TODO: Return sensible data from api functions rather than leaking underlying stack details

export const searchOffers = async (searchData: any) => {
  const res = await axios.get<OffersResponse>("/api/offers-search", {
    params: searchData,
  })
  return {
    offers: res.data.offers,
    count: res.data.count,
    after: res.data.after || null,
  }
}

export const fetchOffer = async (id: string) => {
  const res = axios.get("/api/offers-get", { params: { id } })
  return (await res).data
}

export const inquire = async (inquiryData: any) => {
  return axios.post("/api/inquire", inquiryData)
}

export const sendSearchData = async (searchData: any) => {
  if (searchData.email && searchData.email !== "") {
    return axios.post("/api/events-search", searchData)
  }
}

export const fetchBase = async (uid: string) => {
  const res = await axios.get<BasesResponse>("/api/bases-get", {
    params: { uid: `bm-${uid}` },
  })
  return res.data.results[0]
}
export const fetchLocation = async (uid: string) => {
  const res = await axios.get<any>("/api/location-get", {
    params: { uid },
  })
  return res.data
}
export const fetchBoatType = async (uid: string) => {
  const res = await axios.get<any>("/api/boat-type-get", {
    params: { uid },
  })
  return res.data.results[0]
}

export const fetchBoat = async (uid: string) => {
  return axios.get<Boat>("/api/boats-get", { params: { uid } })
}
