import React from "react"

import {
  Box,
  Button,
  InputGroup,
  InputLeftElement,
  Input,
  FormControl,
  FormErrorMessage,
  Divider,
  Flex,
  Stack,
  Alert,
  AlertDescription,
  AlertIcon,
} from "@chakra-ui/react"
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi"

import { useToast } from "../lib/hooks/useToast"
import { useLocalStorageState } from "src/gatsby/useSessionStorageState"

export const HeroExpertAdvice = (props: { onSubmit(): void }) => {
  const toast = useToast()

  const [emailFromSession, setEmailFromSession] = useLocalStorageState("email")
  const [email, setEmail] = React.useState(emailFromSession || "")
  const [phoneNumber, setPhoneNumber] = React.useState("")

  const submit = (e: any) => {
    e.preventDefault()
    try {
      const dcq = ((window as unknown) as { _dcq: any })._dcq
      if (dcq) {
        dcq.push(["identify", { email, phone: phoneNumber }])
        dcq.push(["track", "Request expert advice", { email }])
      }
      setEmail("")
      setEmailFromSession(email)
      setPhoneNumber("")
      toast({
        status: "success",
        description: "Thank you, we'll be in touch!",
      })
      props.onSubmit()
    } catch (err) {
      console.error(err)
      toast({
        status: "error",
        description: "There was an error submitting your request, please try again.",
      })
    }
  }

  return (
    <>
      <Alert bgColor="white" pb={{ base: 4, sm: 0 }} px={6}>
        <AlertIcon color="primary" boxSize={{ base: "20px", sm: "16px" }} />
        <AlertDescription fontSize="0.9rem" color="primary">
          Provide us with your email and/or phone number and one of our experts will be in touch to help you plan the
          perfect sailing adventure
        </AlertDescription>
      </Alert>
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ base: "stretch", lg: "center" }}
        justify="space-between"
        w="100%"
        bg={["secondaryBg", "white"]}
        px={[8, 3]}
        py={[8, 4]}
        as="form"
        spacing={0}
        onSubmit={submit}
      >
        <Flex w="100%" direction={{ base: "column", lg: "row" }}>
          <Flex w="100% " justify="space-between" align="center" direction={{ base: "column", lg: "row" }}>
            <Box w="100%">
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Box as={HiOutlineMail} color="primary" boxSize="24px" />}
                  />
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    isRequired={!phoneNumber}
                    value={email}
                    type="email"
                    placeholder="Email"
                    border="none"
                    borderRadius="none"
                    minW="15rem"
                    bgColor={{ base: "white", md: "inherit" }}
                  />
                </InputGroup>
                <FormErrorMessage>This field is required</FormErrorMessage>
              </FormControl>
            </Box>
            <Divider
              orientation="vertical"
              display={{ base: "none", lg: "block" }}
              borderColor={["secondaryBg", "gray.300"]}
              h="120%"
              mx={4}
            />
            <Divider
              orientation="horizontal"
              display={{ base: "block", lg: "none" }}
              borderColor={["secondaryBg", "gray.300"]}
              my={[2, 4]}
            />
          </Flex>
          <Flex w="100%" justify="space-between" align="center" direction={{ base: "column", lg: "row" }}>
            <Box w="100%">
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<Box as={HiOutlinePhone} color="primary" boxSize="24px" />}
                  />
                  <Input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    isRequired={!email}
                    placeholder="Phone Number"
                    border="none"
                    borderRadius="none"
                    minW="15rem"
                    bgColor={{ base: "white", md: "inherit" }}
                  />
                </InputGroup>
                <FormErrorMessage>This field is required</FormErrorMessage>
              </FormControl>
            </Box>
            <Divider
              orientation="vertical"
              display={{ base: "none", lg: "block" }}
              borderColor={["secondaryBg", "gray.300"]}
              h="120%"
              mx={[2, 4]}
            />
            <Divider
              orientation="horizontal"
              display={{ base: "block", lg: "none" }}
              borderColor={["secondaryBg", "gray.300"]}
              my={4}
            />
          </Flex>
        </Flex>
        <Button variant="solid" type="submit" minW="94px">
          SUBMIT
        </Button>
      </Stack>
    </>
  )
}
