interface Props {
  [key: string]: string
}

const cleanData = (data: Props): Props =>
  Object.keys(data)
    .filter((k) => data[k] && data[k] !== "")
    .reduce((res, k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res[k] = data[k]
      return res
    }, {})

const formDataToSearchParams = (searchData: any) => {
  const params = cleanData(searchData)

  if (params.destination) {
    if (params.destination !== "all") {
      if (params.destination.startsWith("country[~")) {
        // Entire country selected
        params.destinationCountry = params.destination.slice(9)
        if (params.destinationSailingAreas) delete params.destinationSailingAreas
      } else {
        // City/region selected
        params.destinationSailingAreas = params.destination
        if (params.destinationCountry) delete params.destinationCountry
      }
    } else {
      if (params.destinationSailingAreas) delete params.destinationSailingAreas
      if (params.destinationCountry) delete params.destinationCountry
    }
  }

  return params
}
export const adviceDataToSearchParams = (data: any) => {
  const params = cleanData(data)
  delete params.country
  delete params.email

  if (params.sailingArea) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    params.sailingArea = params.sailingArea.uid
  }
  return params
}

const searchParamstoQueryString = (params: Record<string, any>) => new URLSearchParams(params).toString()

export { formDataToSearchParams, searchParamstoQueryString }
