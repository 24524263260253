import React from "react"
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

import { useFormContext } from "react-hook-form"
import { OnChangeDateCallback } from "react-calendar"

import { Calendar as CalendarIcon } from "./Icons"
import { BoatCalendar } from "./BoatCalendar"

dayjs.extend(customParseFormat)

export const HeroCheckin = () => {
  const {
    setValue,
    formState: { dirtyFields },
    register,
    watch,
  } = useFormContext()

  const modalProps = useDisclosure()

  React.useEffect(() => {
    register("checkin")
  }, [register])

  const clearSelection = (e: any) => {
    e.preventDefault()
    setValue("checkin", "")
    modalProps.onToggle()
  }

  const onChangeCheckin: OnChangeDateCallback = (val) => {
    if (Array.isArray(val)) {
      setValue("checkin", dayjs(val[0]).format("DD-MM-YYYY"), { shouldDirty: true })
    } else {
      setValue("checkin", dayjs(val).format("DD-MM-YYYY"), { shouldDirty: true })
    }
    modalProps.onToggle()
  }

  const checkin = watch("checkin") as string
  const checkout = watch("checkout") as string
  return (
    <>
      <InputGroup size="lg" w={{ base: "100%", md: "min-content" }}>
        <InputLeftElement>
          <CalendarIcon color="primary" w={6} h={6} />
        </InputLeftElement>
        <Input
          placeholder="Check-in"
          fontSize="md"
          onClick={modalProps.onToggle}
          border={0}
          borderRadius={0}
          size="lg"
          id="checkin"
          tabIndex={0}
          w={{ base: "100%", md: 160 }}
          role="button"
          readOnly
          bg="white"
          color={dirtyFields.checkin ? "text" : "beige"}
          {...register('checkin')}
        />
      </InputGroup>

      <Modal {...modalProps} isCentered>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalBody py={8}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckin}
              selectedDays={checkout ? [dayjs(checkout, "DD-MM-YYYY").toDate()] : undefined}
              defaultActiveStartDate={new Date()}
              value={checkin ? [dayjs(checkin, "DD-MM-YYYY").toDate()] : null}
            />
            <Flex mt={4} bg="white" justifyContent="space-between">
              <Button variant="clear" onClick={clearSelection}>
                CLEAR SELECTION
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter roundedBottom="md" bg="primary" color="white">
            Yacht charters typically start on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export const HeroCheckout = () => {
  const {
    setValue,
    formState: { dirtyFields },
    register,
    watch,
  } = useFormContext()

  const modalProps = useDisclosure()

  React.useEffect(() => {
    register("checkout")
  }, [register])

  const clearSelection = (e: any) => {
    e.preventDefault()
    setValue("checkout", "")
    modalProps.onToggle()
  }

  const onChangeCheckout: OnChangeDateCallback = (val) => {
    if (Array.isArray(val)) {
      setValue("checkout", dayjs(val[0]).format("DD-MM-YYYY"), { shouldDirty: true })
    } else {
      setValue("checkout", dayjs(val).format("DD-MM-YYYY"), { shouldDirty: true })
    }
    modalProps.onToggle()
  }

  const checkout = watch("checkout") as string
  const checkin = watch("checkin") as string

  return (
    <>
      <InputGroup size="lg" w={{ base: "100%", md: "min-content" }}>
        <InputLeftElement>
          <CalendarIcon color="primary" w={6} h={6} />
        </InputLeftElement>
        <Input
          placeholder="Check-out"
          fontSize="md"
          onClick={modalProps.onToggle}
          border={0}
          borderRadius={0}
          size="lg"
          id="checkout"
          tabIndex={0}
          w={{ base: "100%", md: 160 }}
          role="button"
          readOnly
          bg="white"
          color={dirtyFields.checkout ? "text" : "beige"}
          {...register('checkout')}
        />
      </InputGroup>

      <Modal {...modalProps} isCentered>
        <ModalOverlay />
        <ModalContent overflow="hidden">
          <ModalCloseButton />
          <ModalBody py={8}>
            <BoatCalendar
              showFixedNumberOfWeeks={true}
              onChange={onChangeCheckout}
              selectedDays={checkin ? [dayjs(checkin, "DD-MM-YYYY").toDate()] : undefined}
              tileDisabled={({ date }) => (checkin ? dayjs(date).isBefore(dayjs(checkin, "DD-MM-YYYY")) : false)}
              defaultActiveStartDate={
                checkout
                  ? dayjs(checkout, "DD-MM-YYYY").toDate()
                  : checkin
                  ? dayjs(checkin, "DD-MM-YYYY").toDate()
                  : new Date()
              }
              value={checkout ? dayjs(checkout, "DD-MM-YYYY").toDate() : undefined}
            />
            <Flex mt={4} bg="white" justifyContent="space-between">
              <Button variant="clear" onClick={clearSelection}>
                CLEAR SELECTION
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter roundedBottom="md" bg="primary" color="white">
            Yacht charters typically end on Saturdays. In order to give you the best selection, book from Saturday to
            Saturday.
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
