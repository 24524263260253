exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advice-index-tsx": () => import("./../../../src/pages/advice/index.tsx" /* webpackChunkName: "component---src-pages-advice-index-tsx" */),
  "component---src-pages-advice-results-tsx": () => import("./../../../src/pages/advice/results.tsx" /* webpackChunkName: "component---src-pages-advice-results-tsx" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-boats-boat-uid-index-tsx": () => import("./../../../src/pages/boats/[boatUid]/index.tsx" /* webpackChunkName: "component---src-pages-boats-boat-uid-index-tsx" */),
  "component---src-pages-boats-boat-uid-inquiry-tsx": () => import("./../../../src/pages/boats/[boatUid]/inquiry.tsx" /* webpackChunkName: "component---src-pages-boats-boat-uid-inquiry-tsx" */),
  "component---src-pages-boats-boat-uid-reserve-tsx": () => import("./../../../src/pages/boats/[boatUid]/reserve.tsx" /* webpackChunkName: "component---src-pages-boats-boat-uid-reserve-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-destination-hub-js": () => import("./../../../src/templates/destination-hub.js" /* webpackChunkName: "component---src-templates-destination-hub-js" */),
  "component---src-templates-get-inspired-js": () => import("./../../../src/templates/get-inspired.js" /* webpackChunkName: "component---src-templates-get-inspired-js" */),
  "component---src-templates-sailing-tips-js": () => import("./../../../src/templates/sailing-tips.js" /* webpackChunkName: "component---src-templates-sailing-tips-js" */)
}

