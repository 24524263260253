import React from "react"
import { useFormContext } from "react-hook-form"
import { Input, Flex, IconButton } from "@chakra-ui/react"

import { Passenger } from "./Icons"
import { BiMinus, BiPlus } from "react-icons/bi"

export const HeroGuests = () => {
  const {
    setValue,
    formState: { dirtyFields },
    register,
    watch,
  } = useFormContext()
  const passengers = watch("passengers")

  const increment = () => {
    setValue("passengers", parseInt(passengers, 10) + 1, { shouldDirty: true })
  }
  const decrement = () => {
    if (parseInt(passengers) === 1) return

    setValue("passengers", parseInt(passengers, 10) - 1, { shouldDirty: true })
  }

  return (
    <Flex w={["auto", "10rem"]} bg="white" align="center">
      <Passenger ml={3} mr={2} sx={{ fill: "primary" }} w={6} h={6} />
      <Flex align="center">
        <IconButton
          mx={2}
          aria-label="decrement"
          isDisabled={parseInt(passengers, 10) === 1}
          icon={<BiMinus />}
          onClick={decrement}
          size="xs"
          borderRadius="full"
          variant="outline"
        />
        <Input
          readOnly
          size="lg"
          w="2rem"
          p={0}
          border={0}
          textAlign="center"
          fontSize="md"
          color={dirtyFields.passengers || parseInt(passengers, 10) !== 0 ? ["text"] : ["beige"]}
          {...register("passengers")}
        />
        <IconButton
          mx={2}
          aria-label="increment"
          icon={<BiPlus />}
          onClick={increment}
          size="xs"
          borderRadius="full"
          variant="outline"
        />
      </Flex>
    </Flex>
  )
}
