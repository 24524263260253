import { Text } from "@chakra-ui/react"
import React from "react"
import Link from "./Link"

const Covid = (props) => (
  <Text mx={4} textAlign="center" {...props}>
    COVID 19: You can change your booking on most of our boats if your travel
    plans are affected by coronavirus.
    <br />
    <Link to="/covid-19/"> See here for more details</Link>
  </Text>
)

export default Covid
