// "It is better to have 100 functions operate on one data structure than to have 10 functions operate on 10 data structures." - Alan J. Perlis

const hasImages = (boat) => boat && Array.isArray(boat.images) && boat.images.length > 0

const imageHasURL = (img) => img && img.cardImageSrc && img.cardImageSrc !== ""

const imageByType = (boat, type) => {
  if (!boat) return null
  if (hasImages(boat)) {
    const img = boat.images.filter((i) => i.type === type)[0]
    if (imageHasURL(img)) {
      return img.cardImageSrc
    }
    return null
  }
  return null
}

const planImage = (boat) => imageByType(boat, "PLAN_IMAGE")

const mainImage = (boat) => imageByType(boat, "MAIN_IMAGE")

const nonPlanImages = (boat) => {
  if (hasImages(boat)) {
    const notMainOrPlan = boat.images
      .filter((i) => !["MAIN_IMAGE", "PLAN_IMAGE"].includes(i.type))
      .map((i) => i.cardImageSrc)
      .filter((ulr) => ulr) // removes null, undefined, etc
    const main = mainImage(boat)
    return [main, ...notMainOrPlan]
  }
  return []
}

export { planImage, mainImage, nonPlanImages }
