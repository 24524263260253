import React from "react"
import { chakra } from "@chakra-ui/react"

const mapCur = (cur) => {
  switch (cur) {
    case "EUR":
      return "€"
    default:
      return cur
  }
}

const PriceText = ({ currency, price }) => (
  <>
    {mapCur(currency)}
    {Math.round(price)}
  </>
)

const PriceTag = ({ currency, startPrice, price }) => {
  if (!(currency && startPrice && price)) {
    return null
  }
  return (
    <>
      {Math.round(startPrice) !== Math.round(price) && (
        <chakra.span
          sx={{
            color: "muted",
            fontWeight: "normal",
            textDecoration: "line-through",
          }}
        >
          <PriceText currency={currency} price={startPrice} />
        </chakra.span>
      )}
      <chakra.span sx={{ fontWeight: "normal", color: "primary", ml: 1 }}>
        <PriceText currency={currency} price={price} />
      </chakra.span>
    </>
  )
}

const priceText = (cur, price) => `${mapCur(cur)}${price}`

export { PriceTag, PriceText, priceText }
