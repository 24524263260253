import React from "react"
import { Tabs, TabList, TabPanels, Tab, TabPanel, Text } from "@chakra-ui/react"
import { FiArrowRight, FiSearch } from "react-icons/fi"

import HeroSearch from "./HeroSearch"
import { HeroExpertAdvice } from "./HeroExpertAdvice"

export const HeroTabs = () => {
  const [tabIndex, setTabIndex] = React.useState(0)

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  return (
    <Tabs variant="enclosed-colored" index={tabIndex} onChange={handleTabsChange} w="950px">
      <TabList>
        <Tab
          bgColor="gray.100"
          fontSize={18}
          fontWeight={600}
          border="none"
          color="primary"
          p={4}
          _focus={{
            bgColor: "white",
          }}
        >
          <Text mr={8}>See Boats</Text>
          <FiSearch />
        </Tab>
        <Tab
          bgColor="gray.100"
          fontSize={18}
          fontWeight={600}
          border="none"
          color="primary"
          p={4}
          _focus={{
            bgColor: "white",
          }}
        >
          <Text mr={8}>Get Expert Advice</Text>
          <FiArrowRight />
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel p={0}>
          <HeroSearch />
        </TabPanel>
        <TabPanel p={0}>
          <HeroExpertAdvice onSubmit={() => setTabIndex(0)} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
