/* eslint-disable react/prop-types */
import React from "react"
import { Icon, IconProps } from "@chakra-ui/react"

export const Length = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 7.48875L11 16.5L8 16.5L12 21L16 16.5L13 16.5L13 7.48875L16 7.48875L12 3L8 7.48875L11 7.48875Z"
      fill="#0C4577"
    />
  </svg>
)

export const Calendar = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M19 4H18V2H16V4H8V2H6V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20Z"
      fill="currentColor"
    />
    <path d="M7 11H9V13H7V11Z" fill="currentColor" />
    <path d="M11 11H13V13H11V11Z" fill="currentColor" />
    <path d="M15 11H17V13H15V11Z" fill="currentColor" />
  </Icon>
)

export const WC = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.5 22V14.5H4V9C4 7.9 4.9 7 6 7H9C10.1 7 11 7.9 11 9V14.5H9.5V22H5.5ZM18 22V16H21L18.46 8.37C18.18 7.55 17.42 7 16.56 7H16.44C15.58 7 14.81 7.55 14.54 8.37L12 16H15V22H18ZM7.5 6C8.61 6 9.5 5.11 9.5 4C9.5 2.89 8.61 2 7.5 2C6.39 2 5.5 2.89 5.5 4C5.5 5.11 6.39 6 7.5 6ZM16.5 6C17.61 6 18.5 5.11 18.5 4C18.5 2.89 17.61 2 16.5 2C15.39 2 14.5 2.89 14.5 4C14.5 5.11 15.39 6 16.5 6Z"
      fill="#0C4577"
    />
  </svg>
)

export const AC = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5161 13.71L18.2903 14.25L16.3548 13.26V10.74L18.2903 9.75L20.5161 10.29L21 8.58L19.2581 8.13L19.7419 6.51L17.9032 6.06L17.3226 8.13L15.3871 9.12L13.0645 7.86V5.79L14.6129 4.26L13.2581 3L12 4.17L10.7419 3L9.3871 4.26L11.0323 5.79V7.86L8.6129 9.12L6.67742 8.13L6.09677 6.06L4.25806 6.51L4.74194 8.13L3 8.49L3.48387 10.2L5.70968 9.66L7.64516 10.65V13.26L5.70968 14.25L3.48387 13.8L3 15.51L4.74194 15.96L4.25806 17.58L6.09677 18.03L6.67742 15.96L8.6129 14.97L10.9355 16.23V18.3L9.29032 19.83L10.7419 21L12 19.83L13.2581 21L14.6129 19.74L12.9677 18.21V16.14L15.3871 14.79L17.3226 15.78L17.9032 17.85L19.7419 17.4L19.2581 15.78L21 15.33L20.5161 13.71ZM9.58065 10.74L12 9.39L14.4194 10.74V13.35L12 14.7L9.58065 13.35V10.74V10.74Z"
      fill="#0C4577"
    />
  </svg>
)

export const Draught = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.201 12.2781L19.1894 12.3168L19.1842 12.357C19.0643 13.2849 18.4632 14.5326 17.8468 15.5979C17.5451 16.1193 17.251 16.5781 17.0324 16.9066C16.9493 17.0314 16.8773 17.1371 16.8201 17.22H15.5719H12.246H8.91942H7.66206C7.60533 17.1366 7.53404 17.0306 7.45185 16.9056C7.23446 16.5749 6.94198 16.1134 6.64133 15.59C6.02726 14.521 5.42554 13.2714 5.29884 12.3527L5.29244 12.3063L5.27753 12.2619C5.24845 12.1753 5.25474 12.0706 5.30331 11.9704C5.34139 11.8919 5.41411 11.8285 5.50356 11.8001L5.50358 11.8002L5.51148 11.7975L6.58419 11.4374L6.92506 11.323V10.9634V7.00205C6.92506 6.32046 7.46394 5.78718 8.08818 5.78718H11.4144H11.9144V5.28718V1.5H12.5775V5.28718V5.78718H13.0775H16.4038C17.028 5.78718 17.5669 6.32046 17.5669 7.00205V10.9634V11.3221L17.9066 11.437L18.971 11.7972L18.971 11.7972L18.9801 11.8001C19.0695 11.8285 19.1422 11.8919 19.1803 11.9704L19.6302 11.7522L19.1803 11.9704C19.2232 12.0588 19.2333 12.1696 19.201 12.2781Z"
      stroke="#0C4577"
    />
    <path d="M16.2 18.5999H8.28174L12.24 22.9999L16.2 18.5999Z" fill="#0C4577" />
    <path d="M3 16.84H21.48V18.6H3V16.84Z" fill="#0C4577" />
  </svg>
)

export const Beam = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5113 11H7.5V8L3 12L7.5 16V13H16.5113V16L21 12L16.5113 8V11Z" fill="#0C4577" />
  </svg>
)

export const Quality = ({ className }: { className?: string }) => (
  <svg className={className} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#F6F4F1" />
    <path
      d="M37.5 15H17.5C16.1125 15 15 16.169 15 17.6269V32.0752C15 33.5331 16.1125 34.7021 17.5 34.7021H22.5V41.2695L27.5 38.6425L32.5 41.2695V34.7021H37.5C38.8875 34.7021 40 33.5331 40 32.0752V17.6269C40 16.169 38.8875 15 37.5 15ZM37.5 32.0752H17.5V29.4482H37.5V32.0752ZM37.5 25.5078H17.5V17.6269H37.5V25.5078Z"
      fill="#0C4577"
    />
  </svg>
)

export const Transparency = ({ className }: { className?: string }) => (
  <svg className={className} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#F6F4F1" />
    <path
      d="M33.2292 31.0417H32.0771L31.6688 30.6479C33.0979 28.9854 33.9583 26.8271 33.9583 24.4792C33.9583 19.2438 29.7146 15 24.4792 15C19.2438 15 15 19.2438 15 24.4792C15 29.7146 19.2438 33.9583 24.4792 33.9583C26.8271 33.9583 28.9854 33.0979 30.6479 31.6688L31.0417 32.0771V33.2292L38.3333 40.5063L40.5063 38.3333L33.2292 31.0417ZM24.4792 31.0417C20.8479 31.0417 17.9167 28.1104 17.9167 24.4792C17.9167 20.8479 20.8479 17.9167 24.4792 17.9167C28.1104 17.9167 31.0417 20.8479 31.0417 24.4792C31.0417 28.1104 28.1104 31.0417 24.4792 31.0417Z"
      fill="#0C4577"
    />
  </svg>
)

export const VerifiedBoats = ({ className }: { className?: string }) => (
  <svg className={className} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#F6F4F1" />
    <path
      d="M34.4271 20.6823L32.7897 19L25.4271 26.5645L27.0645 28.2468L34.4271 20.6823ZM39.351 19L27.0645 31.6234L22.2103 26.648L20.5729 28.3303L27.0645 35L41 20.6823L39.351 19ZM14 28.3303L20.4916 35L22.129 33.3177L15.649 26.648L14 28.3303Z"
      fill="#0C4577"
    />
  </svg>
)

export const TrustInstagram = ({ className }: { className?: string }) => (
  <svg className={className} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="circle881" fill="#F6F4F1" r="27.5" cy="27.5" cx="27.5" />
    <path
      d="M 35.203764,13.677744 H 21.020418 c -3.916618,0 -7.091673,3.175055 -7.091673,7.091673 v 14.183346 c 0,3.91659 3.175055,7.091673 7.091673,7.091673 h 14.183346 c 3.91659,0 7.091673,-3.175083 7.091673,-7.091673 V 20.769417 c 0,-3.916618 -3.175083,-7.091673 -7.091673,-7.091673 z"
      stroke="#0C4577"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path1586"
    />
    <path
      d="m 34.16821,26.90745 c 0.186822,1.259913 -0.02831,2.546774 -0.614968,3.677395 -0.586657,1.130621 -1.515014,2.047471 -2.65275,2.620049 -1.137737,0.572728 -2.427172,0.772116 -3.684662,0.5697 -1.257641,-0.202415 -2.419359,-0.796188 -3.320056,-1.696839 -0.900681,-0.900651 -1.494424,-2.06246 -1.696794,-3.31995 -0.202355,-1.257642 -0.003,-2.546925 0.56964,-3.684813 0.572669,-1.137721 1.489519,-2.065941 2.62017,-2.652599 1.130621,-0.586657 2.417331,-0.801865 3.677395,-0.615028 1.285195,0.190576 2.475012,0.789466 3.393831,1.708194 0.918667,0.918743 1.517587,2.108544 1.708194,3.393891 z"
      stroke="#0C4577"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path1588"
    />
    <path
      d="m 36.438838,19.534343 h 0.01514"
      stroke="#0C4577"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      id="path1590"
    />
  </svg>
)

export const Heart = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7663 7.2377C18.3753 6.84531 17.9111 6.53404 17.4002 6.32168C16.8893 6.10931 16.3417 6 15.7887 6C15.2357 6 14.6881 6.10931 14.1772 6.32168C13.6663 6.53404 13.2021 6.84531 12.8112 7.2377L11.9998 8.05166L11.1884 7.2377C10.3987 6.44548 9.32768 6.00041 8.21089 6.00041C7.09409 6.00041 6.02303 6.44548 5.23334 7.2377C4.44365 8.02993 4 9.10441 4 10.2248C4 11.3452 4.44365 12.4196 5.23334 13.2119L6.0447 14.0258L11.9998 20L17.9549 14.0258L18.7663 13.2119C19.1574 12.8197 19.4677 12.354 19.6794 11.8415C19.891 11.3289 20 10.7796 20 10.2248C20 9.67 19.891 9.12064 19.6794 8.60811C19.4677 8.09557 19.1574 7.6299 18.7663 7.2377V7.2377Z"
      stroke="#0C4577"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TrustHeart = ({ className }: { className?: string }) => (
  <svg className={className} width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle id="circle881" fill="#F6F4F1" r="27.5" cy="27.5" cx="27.5" />
    <path
      id="path834"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeWidth="3.5"
      stroke="#0c4577"
      d="m 37.913752,18.460902 c -0.601772,-0.61553 -1.316205,-1.10381 -2.102511,-1.436933 -0.786307,-0.333138 -1.629097,-0.50461 -2.480198,-0.50461 -0.851101,0 -1.693891,0.171472 -2.480198,0.50461 -0.786306,0.333123 -1.500739,0.821403 -2.102358,1.436933 l -1.248794,1.276835 -1.248795,-1.276835 c -1.215397,-1.242732 -2.863763,-1.9409 -4.582571,-1.9409 -1.718825,0 -3.367251,0.698168 -4.582633,1.9409 -1.215382,1.242748 -1.898186,2.928253 -1.898186,4.685775 0,1.757538 0.682804,3.442918 1.898186,4.685775 l 1.248733,1.276741 9.165266,9.371549 9.165264,-9.371549 1.248795,-1.276741 c 0.601928,-0.615233 1.079498,-1.345762 1.405317,-2.149706 0.325665,-0.8041 0.493424,-1.665771 0.493424,-2.536069 0,-0.870298 -0.167759,-1.732063 -0.493424,-2.536053 -0.325819,-0.804007 -0.803389,-1.534489 -1.405317,-2.149722 z"
    />
  </svg>
)

export const Email = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 18H4V8L12 13L20 8V18ZM12 11L4 6H20L12 11Z"
    />
  </Icon>
)

export const Compass = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 8L14 14L8 16L10 10L16 8Z"
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
)

export const Boat = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M6.68744 18.6398C7.35846 18.3105 7.95086 17.8282 8.42797 17.2829C9.31448 18.2961 10.599 19.0921 12.0464 19.0921C13.4938 19.0921 14.7783 18.2961 15.6648 17.2829C16.1419 17.8282 16.7343 18.3105 17.4053 18.6398C17.4053 18.6398 19.842 15.2375 20.1336 13.0493C20.2059 12.8141 20.1878 12.5609 20.0793 12.3438C19.9707 12.1266 19.7717 11.9638 19.5365 11.8914L18.3786 11.5115V7.33224C18.3786 6.33717 17.5645 5.52303 16.5694 5.52303H12.951V1H11.1418V5.52303H7.52336C6.52829 5.52303 5.71415 6.33717 5.71415 7.33224V11.5115L4.54721 11.8914C4.31201 11.9638 4.113 12.1266 4.00445 12.3438C3.89589 12.5609 3.86876 12.8141 3.95017 13.0493C4.25767 15.2116 6.68744 18.6398 6.68744 18.6398ZM7.52336 7.33224H16.5694V10.9235L12.0464 9.14145L7.52336 10.9235V7.33224Z"
      fill="currentColor"
    />
    <path
      d="M19.2829 21.1907C18.0255 21.1907 16.7681 20.7655 15.6645 19.9966C13.4572 21.5435 10.6349 21.5435 8.42763 19.9966C7.32401 20.7655 6.06661 21.1907 4.80921 21.1907H3V22.9999H4.80921C6.05757 22.9999 7.28783 22.6833 8.42763 22.1043C10.7072 23.2713 13.3849 23.2713 15.6645 22.1043C16.8043 22.6923 18.0345 22.9999 19.2829 22.9999H21.0921V21.1907H19.2829Z"
      fill="currentColor"
    />
  </Icon>
)

export const Passenger = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 2C13.1 2 14 2.9 14 4C14 5.1 13.1 6 12 6C10.9 6 10 5.1 10 4C10 2.9 10.9 2 12 2ZM21 9H15V22H13V16H11V22H9V9H3V7H21V9Z" />
  </Icon>
)

export const Cabin = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <mask id="path-1-inside-1" fill="white">
      <rect x="6" y="4" width="13" height="16" rx="1" />
    </mask>
    <rect
      x="6"
      y="4"
      width="13"
      height="16"
      rx="1"
      stroke="currentColor"
      strokeWidth="4"
      mask="url(#path-1-inside-1)"
    />
    <mask id="path-2-inside-2" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80681 3.02134C5.33801 3.11364 5 3.52469 5 4.0025V19.5337C5 20.0115 5.33801 20.4225 5.80681 20.5148L13.4991 22.0294C14.1171 22.1511 14.6923 21.6781 14.6923 21.0483V2.48788C14.6923 1.85805 14.1171 1.38504 13.4991 1.50672L5.80681 3.02134ZM10.7435 12.7222C11.2722 12.7222 11.7008 12.295 11.7008 11.768C11.7008 11.241 11.2722 10.8138 10.7435 10.8138C10.2149 10.8138 9.78627 11.241 9.78627 11.768C9.78627 12.295 10.2149 12.7222 10.7435 12.7222Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.80681 3.02134C5.33801 3.11364 5 3.52469 5 4.0025V19.5337C5 20.0115 5.33801 20.4225 5.80681 20.5148L13.4991 22.0294C14.1171 22.1511 14.6923 21.6781 14.6923 21.0483V2.48788C14.6923 1.85805 14.1171 1.38504 13.4991 1.50672L5.80681 3.02134ZM10.7435 12.7222C11.2722 12.7222 11.7008 12.295 11.7008 11.768C11.7008 11.241 11.2722 10.8138 10.7435 10.8138C10.2149 10.8138 9.78627 11.241 9.78627 11.768C9.78627 12.295 10.2149 12.7222 10.7435 12.7222Z"
      fill="currentColor"
    />
    <path
      d="M5.80681 3.02134L6.19319 4.98366L6.19319 4.98366L5.80681 3.02134ZM5.80681 20.5148L5.42042 22.4771L5.42043 22.4771L5.80681 20.5148ZM13.4991 22.0294L13.1127 23.9918L13.1127 23.9918L13.4991 22.0294ZM13.4991 1.50672L13.8855 3.46904L13.8855 3.46904L13.4991 1.50672ZM7 4.0025C7 4.4803 6.66199 4.89135 6.19319 4.98366L5.42043 1.05901C4.01403 1.33593 3 2.56909 3 4.0025H7ZM7 19.5337V4.0025H3V19.5337H7ZM6.19319 18.5525C6.66199 18.6448 7 19.0559 7 19.5337H3C3 20.9671 4.01402 22.2002 5.42042 22.4771L6.19319 18.5525ZM13.8855 20.0671L6.19319 18.5525L5.42043 22.4771L13.1127 23.9918L13.8855 20.0671ZM12.6923 21.0483C12.6923 20.4184 13.2675 19.9454 13.8855 20.0671L13.1127 23.9918C14.9666 24.3568 16.6923 22.9378 16.6923 21.0483H12.6923ZM12.6923 2.48788V21.0483H16.6923V2.48788H12.6923ZM13.8855 3.46904C13.2675 3.59072 12.6923 3.11771 12.6923 2.48788H16.6923C16.6923 0.598394 14.9666 -0.820637 13.1127 -0.455604L13.8855 3.46904ZM6.19319 4.98366L13.8855 3.46904L13.1127 -0.455604L5.42043 1.05901L6.19319 4.98366ZM9.7008 11.768C9.7008 11.1844 10.1737 10.7222 10.7435 10.7222V14.7222C12.3708 14.7222 13.7008 13.4056 13.7008 11.768H9.7008ZM10.7435 12.8138C10.1737 12.8138 9.7008 12.3516 9.7008 11.768H13.7008C13.7008 10.1304 12.3708 8.81383 10.7435 8.81383V12.8138ZM11.7863 11.768C11.7863 12.3516 11.3134 12.8138 10.7435 12.8138V8.81383C9.11632 8.81383 7.78627 10.1304 7.78627 11.768H11.7863ZM10.7435 10.7222C11.3134 10.7222 11.7863 11.1844 11.7863 11.768H7.78627C7.78627 13.4056 9.11632 14.7222 10.7435 14.7222V10.7222Z"
      fill="currentColor"
      mask="url(#path-2-inside-2)"
    />
  </Icon>
)

export const Minus = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="11.5" transform="rotate(-180 12 12)" stroke="currentColor" />
    <path d="M7 11V13H17V11H7Z" fill="currentColor" />
  </Icon>
)

export const Plus = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="11.5" transform="rotate(-180 12 12)" stroke="currentColor" />
    <path d="M13 7H11V11H7V13H11V17H13V13H17V11H13V7Z" fill="currentColor" />
  </Icon>
)

export const DropPoint = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" />
  </Icon>
)

export const SearchIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M11.5 2a9.5 9.5 0 0 1 7.7 15.07l6.36 6.37a1.5 1.5 0 0 1-2 2.22l-.12-.1-6.37-6.37A9.5 9.5 0 1 1 11.5 2zm0 3a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13z" />
  </Icon>
)
