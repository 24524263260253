import React from "react"
import { navigate } from "gatsby"
import { FormProvider, useForm } from "react-hook-form"
import { Button, Stack, StackDivider } from "@chakra-ui/react"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

import { HeroCheckin, HeroCheckout } from "./HeroDates"
import { sendSearchData } from "../client/lambdas"
import { HeroDestination } from "./HeroDestination"
import { formDataToSearchParams, searchParamstoQueryString } from "../lib/helpers/search-params"
import { HeroGuests } from "./HeroPassengers"
import { useLocation } from "@reach/router"

dayjs.extend(customParseFormat)

const SEARCH_DEFAULTS = {
  destination: "",
  passengers: 6,
  checkin: "",
  checkout: "",
}

const HeroSearch = () => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const params = Object.fromEntries(new URLSearchParams(location.search))
  const destination = params.destination
  const form = useForm({ defaultValues: { ...SEARCH_DEFAULTS, destination: destination || "" } })

  const submit = (data: typeof SEARCH_DEFAULTS) => {
    const newCheckin = data.checkin && dayjs(data.checkin, "DD-MM-YYYY").format("YYYY-MM-DD")
    let newCheckout = data.checkout && dayjs(data.checkout, "DD-MM-YYYY").format("YYYY-MM-DD")
    if (!data.checkout && newCheckin) {
      newCheckout = dayjs(newCheckin).add(1, "w").format("YYYY-MM-DD")
    }
    const params = formDataToSearchParams({ ...data, checkin: newCheckin, checkout: newCheckout })
    sendSearchData({ email: params.email })
    const qs = searchParamstoQueryString(params)
    navigate(qs !== "" ? `/search?${qs}` : `/search`)
  }

  return (
    <FormProvider {...form}>
      <Stack
        align={{ base: "stretch", lg: "center" }}
        as="form"
        divider={<StackDivider borderColor={["secondaryBg", "gray.200"]} />}
        spacing={[2, 4]}
        onSubmit={form.handleSubmit(submit)}
        bg={["secondaryBg", "white"]}
        p={[6, 3]}
        direction={{ base: "column", lg: "row" }}
      >
        <HeroDestination />
        <HeroCheckin />
        <HeroCheckout />
        <HeroGuests />
        <Button variant="solid" type="submit">
          SEARCH
        </Button>
      </Stack>
    </FormProvider>
  )
}

export default HeroSearch
