import React from "react"
import { Box, Heading, List, ListItem } from "@chakra-ui/react"
import PropTypes from "prop-types"

const categories = [
  [
    "Deck",
    [
      "Dinghy",
      "Electric winches",
      ["Bimini", "Bimini top"],
      "Flybridge",
      "Sprayhood",
      "Teak deck",
      "Hydraulic gangway",
      "Cockpit cushion",
      ["Teack cockpit", "Teak cockpit"],
      "Holding tank",
      "Outboard engine",
    ],
  ],
  [
    "Entertainment",
    [
      "Cockpit speakers",
      "DVD player",
      "Radio-CD player",
      "Wi-Fi & Internet",
      "Game console",
      ["Stand up paddle", "Stand-up Paddle"],
      "Diving equipment",
      "Lazy bag",
      "Railing net",
      "Snorkeling equipment",
      "TV",
    ],
  ],
  ["Galley", ["Coffee maker", "Ice maker", "Refrigerator"]],
  ["Interior", ["Electric toilet"]],
  [
    "Navigation",
    [
      "Autopilot",
      "Chart plotter",
      "Bow thruster",
      "Radar",
      ["Chart plotter in cockpit", "GPS Chart plotter - cockpit"],
    ],
  ],
  [
    "Sails",
    ["Spinnaker", "Gennaker", "Racing sails", ["Lazy jack", "Lazy jacks"]],
  ],
  [
    "Electrics",
    [
      ["Air condition", "Air conditioner"],
      "Generator",
      "Inverter",
      "Heating",
      "Water maker",
      "Solar panel",
    ],
  ],
]

const findCategoryLabelPair = (categories, item) => {
  let pair
  categories.forEach(([cat, labels]) => {
    labels.forEach((catalogItem) => {
      if (Array.isArray(catalogItem)) {
        if (catalogItem[0].toLowerCase() === item.toLowerCase()) {
          pair = [cat, catalogItem[1]]
        }
        return
      }
      if (catalogItem.toLowerCase() === item.toLowerCase()) {
        pair = [cat, catalogItem]
      }
    })
  })
  return pair
}

const EquipmentList = ({
  boatData: { equipmentList, mainsailType, genoaType },
}) => {
  // keep the insertion order
  const boatCategories = new Map()
  boatCategories.set("Deck", [])
  boatCategories.set("Entertainment", [])
  boatCategories.set("Galley", [])
  boatCategories.set("Interior", [])
  boatCategories.set("Navigation", [])
  boatCategories.set("Sails", [])
  boatCategories.set("Electrics", [])
  if (typeof mainsailType === "string" && mainsailType.trim() !== "") {
    boatCategories.set("Sails", [
      ...boatCategories.get("Sails"),
      `${mainsailType} main sail`,
    ])
  }
  if (typeof genoaType === "string" && genoaType.trim() !== "") {
    boatCategories.set("Sails", [
      ...boatCategories.get("Sails"),
      `${genoaType} genoa`,
    ])
  }
  equipmentList.forEach((e) => {
    const [cat, label] = findCategoryLabelPair(categories, e)
    if (cat) {
      boatCategories.set(cat, [...boatCategories.get(cat), label])
    }
  })
  const filteredCats = Array.from(boatCategories).filter(
    ([, labels]) => labels.length !== 0
  )
  return (
    <Box
      mt={[0, 12]}
      sx={{
        display: "grid",
        justifyContent: "space-evenly",
        gridTemplateColumns: ["1fr", "max-content max-content max-content"],
        ul: {
          listStyleType: "none",
          pl: 0,
        },
      }}
    >
      {filteredCats.map(([cat, labels]) => (
        <Box key={cat}>
          <Heading as="h3" variant="h3" size="lg" mt={4}>
            {cat}
          </Heading>
          <List variant="plain">
            {labels.map((l) => (
              <ListItem key={l}>{l}</ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  )
}

EquipmentList.propTypes = {
  boatData: PropTypes.shape({
    equipmentList: PropTypes.arrayOf(PropTypes.string).isRequired,
    mainsailType: PropTypes.string,
    genoaType: PropTypes.string,
  }).isRequired,
}

export default EquipmentList
