import { extendTheme } from "@chakra-ui/react"

const t = extendTheme({
  colors: {
    primary: "#0C4577",
    primaryBg: "#e6ebf0",
    beige: "#B8AC9C",
    secondary: "#D2C7B9",
    secondaryBg: "#F6F4F1",
    muted: "#858585",
    white: "#FFFFFF",
    error: "#D33030",
    text: "#2D3748", // gray.700
  },
  fonts: {
    body: 'Muli, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: "DM Serif Display, Georgia, serif",
    monospace: "Menlo, monospace",
  },
  styles: {
    global: {
      button: {
        borderRadius: 0,
      },
      select: {
        borderRadius: 0,
      },
      body: {
        margin: 0,
        padding: 0,
        color: "text",
      },
      a: {
        color: "primary",
        textDecoration: "none",
        _hover: {
          textDecoration: "none",
        },
      },
    },
  },
  components: {
    Popover: {
      baseStyle: {
        borderRadius: 0,
      },
    },
    Select: {
      baseStyle: {
        borderRadius: 0,
      },
    },
    Input: {
      baseStyle: {
        color: "red",
      },
    },
    Badge: {
      baseStyle: {
        fontWeight: "normal",
      },
      sizes: {
        sm: {
          fontSize: "sm",
          py: 0.5,
          px: 2,
        },
      },
      variants: {
        solid: {
          bg: "primary",
          color: "white",
        },
      },
      defaultProps: {
        size: "sm",
        variant: "solid",
      },
    },
    Button: {
      baseStyle: {
        border: 0,
        borderRadius: "none",

        cursor: "pointer",
      },
      variants: {
        solid: {
          bg: "primary",
          color: "white",
          _hover: {
            bg: "primary",
            opacity: ".85",
            transition: "opacity .2s ease-out",
            _disabled: {
              bg: "primary",
              opacity: "0.75",
            },
          },
          _disabled: {
            bg: "primary",
            opacity: "0.75",
          },
        },
        secondary: {
          backgroundColor: "secondaryBg",
          color: "primary",
        },
        clear: {
          bg: "white",
          color: "primary",
          _hover: {
            bg: "white",
            opacity: "0.90",
          },
        },
        navlink: {
          border: 0,
          bg: "white",
          p: 0,
          _hover: {
            color: "primary",
          },
        },
      },
      sizes: {
        lg: {
          fontSize: ["md", "lg"],
        },
      },
      defaultProps: {
        variant: "solid",
        sizes: "md",
      },
    },
    Heading: {
      variants: {
        h2: {
          fontWeight: "normal",
          color: "primary",
        },
        h3: {
          fontFamily: "body",
          fontWeight: "normal",
          color: "primary",
        },
        h4: {
          fontFamily: "body",
          fontWeight: "normal",
        },
        h5: {
          fontFamily: "body",
          fontWeight: "normal",
        },
        h6: {
          fontFamily: "body",
          fontWeight: "normal",
        },
      },
    },
    List: {
      baseStyle: {
        container: {},
        item: {
          listStyleType: "disc",
        },
      },
      sizes: {
        md: {
          container: {
            my: 4,
          },
          item: {
            mx: 8,
          },
        },
      },
      defaultProps: {
        size: "md",
      },
      variants: {
        plain: {
          item: {
            listStyleType: "none",
            mx: 0,
          },
        },
      },
    },
  },
})

export type CustomTheme = typeof t
export default t
