import React from "react"
import { PageProps } from "gatsby"
import { useLocation } from "@reach/router"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  chakra,
  Flex,
  Grid,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Global } from "@emotion/react"
import { BiCheckShield } from "react-icons/bi"
import { useQuery } from "react-query"
import axios from "axios"

import { Quality, TrustHeart, TrustInstagram } from "src/components/Icons"
import BoatBase from "src/components/BoatBase"
import BoatCTA from "src/components/BoatCTA"
import BoatFeatures from "src/components/BoatFeatures"
import BoatImages from "src/components/BoatImages"
import EquipmentList from "src/components/EquipmentList"
import Extras from "src/components/Extras"
import StickyHeader from "src/components/StickyHeader"
import Layout from "src/components/Layout"
import Section from "src/components/Section"

import SEO from "src/components/SEO"
import { planImage } from "src/domain/boat"
import useInView from "src/lib/hooks/useInView"
import Covid from "src/components/Covid"
import { Offer, OffersResponse } from "src/lib/types/offer"
import { Boat } from "src/lib/types/boat"
import { Base, BasesResponse } from "src/lib/types/base"
import { FAQS } from "src/lib/static/faqs"
import { boatAboutText, boatLocationText } from "src/lib/static/boat"
import { TrustPilot } from "src/components/TrustPilot"

import { useLocalStorageState } from "src/gatsby/useSessionStorageState"

import { SimilarBoat } from "src/components/SimilarBoat"
import { useDripTrack } from "src/lib/hooks/useDripTrack"
import { boatDripData } from "src/lib/helpers/drip-data"

interface FetchBase {
  content: Base
  city: string
  country: string
  mapURL: string
}

const BoatDetails = (props: PageProps<{ boatUid: string }>) => {
  const { boatUid } = props.params
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const boatParams = Object.fromEntries(new URLSearchParams(location.search))
  const offerId = boatParams.offerId

  const fetchBoat = () => axios.get<Boat>("/api/boats-get", { params: { uid: boatUid } })
  const { data: boatResponse, isLoading: isBoatLoading } = useQuery(["fetchBoat", boatUid], fetchBoat, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!boatUid,
  })
  const boat = boatResponse?.data

  const fetchOffer = () => axios.get<Offer>("/api/offers-get", { params: { id: offerId } })
  const { data: offerResponse, isLoading: isOfferLoading } = useQuery(["fetchOffer", offerId], fetchOffer, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!offerId,
  })
  const offer = offerResponse?.data

  const [search] = useLocalStorageState("searchParams")
  useDripTrack("Visit boat details", boatDripData(boat, offer, search), { enabled: !!boat })

  const baseId = offer?.startBase.id || boat?.base.id
  const fetchBase = () => axios.get<BasesResponse>("/api/bases-get", { params: { uid: `bm-${baseId}` } })
  const { data: baseResponse } = useQuery(["fetchBase", baseId], fetchBase, {
    staleTime: 6 * 60 * 60 * 1000,
    enabled: !!baseId,
  })
  const base: FetchBase = baseResponse
    ? offer?.startBase.id
      ? {
          content: baseResponse?.data.results[0],
          city: offer.startBase.city || "",
          country: offer.startBase.country || "",
          mapURL: offer.startBase.mapURL || "",
        }
      : {
          content: baseResponse?.data.results[0],
          city: boat?.base.city || "",
          country: boat?.base.country || "",
          mapURL: boat?.base.mapURL || "",
        }
    : ({} as FetchBase)

  const [isInView, setStickyRef] = useInView()

  const device = useBreakpointValue({ base: "mobile", md: "desktop" })

  const similarSearchParams =
    search && Object.keys(search).length > 0
      ? search
      : {
          boatType: boat?.kind.toLowerCase(),
          baseCountry: boat?.base.country,
          minLength: boat?.length ? (boat.length - 1).toFixed(2) : undefined,
          maxLength: boat?.length ? (boat.length + 1).toFixed(2) : undefined,
          minYear: boat?.year ? boat.year - 1 : undefined,
          maxYear: boat?.year ? boat.year + 1 : undefined,
        }

  const fetchSimilarOffers = () =>
    axios.get<OffersResponse>("/api/offers-search", { params: { ...similarSearchParams, after: 0 } })

  const { data: response, isLoading: isSimilarOffersLoading } = useQuery(
    ["similarOffers", similarSearchParams],
    fetchSimilarOffers,
    { staleTime: 6 * 60 * 60 * 1000 }
  )
  const similarOffers = response?.data.offers

  return (
    <Layout invertedNav noHero>
      {isBoatLoading || isOfferLoading ? (
        <Center h={400} w="100%">
          <Spinner />
        </Center>
      ) : !boat ? (
        <Center h={400} w="100%" />
      ) : (
        <>
          {!isInView && device === "mobile" && (
            <Global
              styles={() => ({
                "[id^=gb-widget]": {
                  bottom: "90px !important",
                },
              })}
            />
          )}
          <SEO title={`${boat.modelName} | Book Now`} noindex nofollow />
          <StickyHeader boat={boat} offer={offer} show={!isInView} />
          {/* eslint-disable-next-line */}
          {/*  @ts-ignore */}
          <BoatCTA boat={boat} offer={offer} ref={setStickyRef} />
          <Covid />
          <BoatImages boat={boat} />
          <Center flexDir="column" my={10}>
            {offer?.boat.company.extras.map((extra) => {
              const text =
                extra === "Flexible Rescheduling"
                  ? "Flexible rescheduling - reschedule this boat if you can't travel for COVID related reasons"
                  : "Flexible refund - get a refund for this boat if you can't travel for COVID related reasons"
              return (
                <Box key={extra} p={4} border="1px solid" borderColor="primary">
                  <Text color="primary">{text}</Text>
                </Box>
              )
            })}
            <SimpleGrid my={10} spacing={10} columns={{ base: 1, lg: 3 }}>
              <Flex maxW={300} align={{ base: "center", md: "flex-start" }}>
                <Quality />
                <Box ml={4}>
                  <Text fontSize="1rem">Quality</Text>
                  <Text fontSize="1rem" color="muted">
                    This is one of over 9000 boats we have on our site
                  </Text>
                </Box>
              </Flex>
              <Flex maxW={300} align={{ base: "center", md: "flex-start" }}>
                <TrustHeart />
                <Box ml={4}>
                  <Text fontSize="1rem">Sustainability</Text>
                  <Text fontSize="1rem" color="muted">
                    When you book this boat, we pledge to remove plastic from the ocean
                  </Text>
                </Box>
              </Flex>
              <Flex maxW={300} align={{ base: "center", md: "flex-start" }}>
                <TrustInstagram />
                <Box ml={4}>
                  <Text fontSize="1rem">Community</Text>
                  <Text fontSize="1rem" color="muted">
                    Book this boat and join the HighSails community
                  </Text>
                </Box>
              </Flex>
            </SimpleGrid>
            <Box>
              <TrustPilot />
            </Box>
          </Center>
          <Text sx={{ mt: [12, 20], mb: [0, 4], color: "muted", textAlign: "center" }}>BOAT NAME</Text>
          <Section headline={boat.name} px={{ base: 0, md: 20 }} divider>
            <Center px={{ base: 0, md: 20 }} my={8} flexDirection="column">
              <Flex bg="secondaryBg" p={3} px={8} align="center">
                <Box mr={4} as={BiCheckShield} color="primary" boxSize="26px" />
                <Box>
                  <Text fontSize="0.9rem" fontWeight={700}>
                    {boat.name} has been verified
                  </Text>
                  <Text>All information and pictures are correct</Text>
                </Box>
              </Flex>
            </Center>
            <Box
              py={[0, 8]}
              mt={8}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: ["column", "row"],
              }}
            >
              {/* eslint-disable-next-line */}
              {/*  @ts-ignore */}
              <BoatFeatures boatData={boat} start={0} end={4} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: ["column", "row"],
              }}
            >
              {/* eslint-disable-next-line */}
              {/*  @ts-ignore */}
              <BoatFeatures boatData={boat} start={4} end={9} />
            </Box>
            <Center px={{ base: 0, md: 20 }} my={8} flexDirection="column">
              <Stack textAlign="center">
                <Text>{boatAboutText(boat)}</Text>
                <Box>
                  <Text color="muted">{boatLocationText(boat)}</Text>
                </Box>
              </Stack>
            </Center>
          </Section>
          {planImage(boat) && (
            <Section my={[12, 20]} divider headline="Boat floor plan">
              <chakra.img
                src={planImage(boat)}
                alt={`${boat.name} floor plan`}
                sx={{ maxWidth: "100%" }}
                mx="auto"
                mt="3rem"
              />
            </Section>
          )}
          <Section divider headline="Amenities & Equipment">
            <EquipmentList boatData={boat} />
          </Section>
          <Section my={[12, 16]} divider headline="Extras">
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: ["1fr", "1fr 1fr"],
                gridColumnGap: [5],
                pt: 4,
              }}
            >
              <Box sx={{ textAlign: "center", gridColumn: "1/-1", mt: -3, mb: 4 }}>To be paid at the base</Box>
              <Extras boatData={boat} />
            </Box>
          </Section>

          {base && <BoatBase base={base} boatName={boat.name} />}

          <Section py={[12, 16]} px={[0, 0, 10, 40]} highlighted headline="FAQS">
            <Accordion mt={10} allowToggle>
              {FAQS.map(({ question, answer }) => (
                <AccordionItem key={question}>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>{answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Section>
          {isSimilarOffersLoading ? (
            <Center mt={4}>
              <Spinner size="sm" ml={8} />
            </Center>
          ) : (
            similarOffers &&
            similarOffers.filter((o) => boat && o.boat.id !== boat.id).length > 0 && (
              <Section py={[12, 16]} headline="Similar Boats">
                <Grid
                  mt={8}
                  sx={{
                    gridTemplateColumns: ["repeat(3, 85%)", "repeat(3, minmax(150px, 1fr))"],
                    gridColumnGap: [4, 6, 8],
                    overflowX: ["scroll", "hidden"],
                    scrollSnapType: "x mandatory",
                  }}
                >
                  {similarOffers
                    .filter((o) => boat && o.boat.id !== boat.id)
                    .slice(0, 3)
                    .map((offer) => (
                      <SimilarBoat key={`${offer.id}`} offer={offer} />
                    ))}
                </Grid>
              </Section>
            )
          )}
        </>
      )}
    </Layout>
  )
}

export default BoatDetails
