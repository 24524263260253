import React from "react"
import { InputGroup, InputLeftElement, Select } from "@chakra-ui/react"
import { useFormContext } from "react-hook-form"

import { Compass } from "./Icons"
import destinations from "../lib/static/destinations.json"

const allDestinations = (destinations.reduce((value: any, item: any) => {
  return [...value, item, ...(item.items || []).map((sub: any) => ({ ...sub, pre: true }))]
}, []) as unknown) as { label: string; value: string; key?: string; pre?: boolean }[]

export const HeroDestination = () => {
  const {
    formState: { dirtyFields },
    register,
  } = useFormContext()

  return (
    <InputGroup w={["auto", "15rem"]} bg="white" size="lg">
      <InputLeftElement>
        <Compass w={6} h={6} color="primary" />
      </InputLeftElement>
      <Select
        {...register('destination')}
        pl={8}
        border="none"
        fontSize="md"
        color={dirtyFields?.destination ? "text" : "gray.400"}
        _focus={{
          border: "none"
        }}
      >
        <option value="" disabled>
          Sailing areas
        </option>
        {allDestinations.map((item, index) => (
          <option key={`destinationItem${index}`} value={item.value}>
            {!!item.pre && "\u2003"}
            {item.label}
          </option>
        ))}
      </Select>
    </InputGroup>
  )
}
